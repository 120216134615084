import { t } from '@lingui/macro'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useEffect } from 'react'
import useSessionStorage from 'react-use/lib/useSessionStorage'
import { GetDataError } from 'restful-react'
import {
  LoginModel,
  usePostAuth,
  useGetCSRFToken,
  usePostAuthEncript,
  SecurityTokens,
  useSelectCompanie,
  SelectEntityModel
} from '../../api/api'
import { useAPISecurity } from '../../security/APISecurityContext'
import { csrfTokenInitial, csrfTokensLocalStorageKey } from '../../security/getAccessTokenAuthorizationHeader'
import { KEY_PUBLIC } from '../../config'
import { encryptObjectWithPublicKey } from '../../security/UtilEncode'

const defaultError = 0
const milliSecondsToSeconds = 1000
const IntervalFourMinutes = 240000

interface IErrorHttp {
  status: number
}

const getMessageFromStatusCode = (statusCode: number) => {
  switch (statusCode) {
    case 400:
      return t`Los datos proporcionados son incorrectos.`

    case 401:
      return t`Usuario o clave inválido`

    case 403:
      return t`No está autorizado para acceder desde esta ubicación.`

    case 500:
      return t`Error interno. En este momento no podemos atender su solicitud.`

    default:
      return t`No es posible comunicarse con el servicio, puede intentarlo de nuevo más tarde.`
  }
}

const isGetDataError = (obj: any): obj is GetDataError<any> => {
  return 'message' in obj && 'data' in obj
}

export const useLogin = (setErrorMessage: (message: string) => void) => {
  const postAuthApi = usePostAuth({})
  const postAuthApiEncript = usePostAuthEncript({})
  const postSelectCompanie = useSelectCompanie({})
  const { setSecurityTokens, setIsValidAuth, setIsAuthenticated } = useAPISecurity()
  const [, setCsrfTokens] = useSessionStorage(csrfTokensLocalStorageKey, csrfTokenInitial)
  const getCSRF = useGetCSRFToken({})

  useEffect(() => {
    if (!getCSRF.loading && getCSRF.data) {
      setCsrfTokens(getCSRF.data)
    }
  }, [getCSRF.data, getCSRF.loading])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!getCSRF.loading) {
        void getCSRF.refetch()
      }
    }, IntervalFourMinutes)

    return () => clearInterval(interval)
  }, [getCSRF])

  const handleTokens = (tokens: SecurityTokens) => {
    const jwt = jwtDecode<JwtPayload>(tokens.access_token ?? '')

    if (!jwt.iat) {
      throw new Error('Invalid token issued')
    }

    const timeDifference = Math.floor(Date.now() / milliSecondsToSeconds) - jwt.iat
    setSecurityTokens({ accessToken: tokens.access_token, refreshToken: tokens.refresh_token, timeDifference })
  }

  const handleLoginError = (error: any) => {
    if (isGetDataError(error)) {
      setErrorMessage(getMessageFromStatusCode(error?.status || defaultError))
    } else {
      setErrorMessage(t`El token de acceso no es válido.`)
    }
    void getCSRF.refetch()
  }

  const handleChangeEntityError = (error: IErrorHttp) => {
    if (error.status === 403) {
      setErrorMessage(getMessageFromStatusCode(error.status))
    } else {
      setErrorMessage(t`La sesión ha expirado. ingrese nuevamente`)
    }

    setIsValidAuth(false)
    setSecurityTokens({ accessToken: '', refreshToken: '', timeDifference: 0 })
    void getCSRF.refetch()
  }

  const postLogin = (loginData: LoginModel) => {
    setErrorMessage('')

    if (KEY_PUBLIC !== '' && KEY_PUBLIC !== null) {
      const loginEncript = { tokenLogin: encryptObjectWithPublicKey(loginData, KEY_PUBLIC) ?? '' }
      postAuthApiEncript
        .mutate(loginEncript)
        .then(tokens => {
          handleTokens(tokens)
        })
        .then(() => setIsValidAuth(true))
        .catch(handleLoginError)
    } else {
      postAuthApi
        .mutate(loginData)
        .then(tokens => handleTokens(tokens))
        .then(() => setIsValidAuth(true))
        .catch(handleLoginError)
    }
  }

  const postSelectCompany = (requestSelect: SelectEntityModel) => {
    postSelectCompanie
      .mutate(requestSelect)
      .then(tokens => handleTokens(tokens))
      .then(() => setIsAuthenticated(true))
      .catch(handleChangeEntityError)
  }

  return {
    loading: postAuthApi.loading || postAuthApiEncript.loading || postSelectCompanie.loading,
    postLogin,
    postSelectCompany
  }
}
